.studio .studio-margin {
  margin-left: 1.4rem;
  margin-right: 1.4rem;
}

.studio em {
  color: #ffa100;
  font-family: 'DINPro-Bold', 'Helvetica Neue', 'Arial', sans-serif;
  font-style: normal;
}

.studio h1,
.studio h2 {
  margin-top: 0;
  padding-top: 3.3rem;
  text-transform: uppercase;
}

.studio h1 {
  font-family: Montserrat-Bold, 'Helvetica Neue', Arial, sans-serif;
}

.studio h2 {
  font-family: Montserrat-Regular, 'Helvetica Neue', Arial, sans-serif;
}
.studio h2 em {
  color: #ffc200;
  font-family: 'Montserrat-Regular', 'Helvetica Neue', 'Arial', sans-serif;
  font-weight: lighter;
}

.studio h3 {
  font-family: 'Montserrat-Regular', 'Helvetica Neue', 'Arial', sans-serif;
  letter-spacing: 0.18rem;
  margin-bottom: 1.3rem;
  z-index: 1;
}

.studio hr {
  border: solid 0.05rem #ffa100;
}

.studio .card {
  border-color: #ffc200;
  border-width: 0.1rem;
  border-radius: 0;
  margin-bottom: 1.4rem;
  padding: 2.6rem 2rem 2rem;
}

.studio .expertise hr,
.studio .partners hr,
.studio .services hr,
.studio .trust hr {
  margin-bottom: 2rem;
  margin-left: 0;
  margin-top: 0.4rem;
}

.studio .expertise h2 {
  padding-top: 2.5rem;
}

.studio .expertise h3 {
  margin-right: 3.9rem;
}

.studio .expertise .description {
  margin-top: 4rem;
}

.studio .expertise img {
  position: absolute;
  right: 0.5rem;
  top: 1.5rem;
}

.studio .expertise .card .text p {
  position: relative;
}

.studio .expertise .icon-eye {
  margin-top: 1rem;
  width: 6rem;
}

.studio .expertise .icon-mobile {
  width: 4rem;
}

.studio .expertise .icon-hand {
  margin-top: -1rem;
  width: 3.5rem;
}

.studio .values {
  background-image: radial-gradient(circle at 59% 38%, #fff, #f6f6f6);
  margin-top: 4rem;
  padding-bottom: 3rem;
}
.studio .values img {
  bottom: -4.5rem;
  position: absolute;
  right: 0.5rem;
  width: 11.5rem;
}
.studio .values .card {
  background-color: transparent;
  margin-bottom: 6.7rem;
}
.studio .values .card p {
  margin-bottom: 4rem;
}
.studio .values .row {
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 768px) {
  .studio .card {
    border-width: 0.2rem;
  }
  .studio .studio-margin {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .studio .expertise h2,
  .studio .values h2,
  .studio .services h2,
  .studio .trust h2,
  .studio .partners h2 {
    font-size: 3rem;
    padding-top: 5.1rem;
  }
  .studio .expertise h2 {
    padding-top: 2.5rem;
  }
  .studio .expertise hr,
  .studio .values hr,
  .studio .services hr,
  .studio .trust hr,
  .studio .partners hr {
    border-width: 0.15rem;
    margin-bottom: 3.5rem;
    margin-top: 2.5rem;
    width: 6rem;
  }
  .studio .expertise .icon-eye {
    margin-top: 0;
    width: 6.9rem;
  }
  .studio .partners h2 {
    padding-top: 2.1rem;
  }
  .studio .values {
    margin-top: 6rem;
  }
  .studio .values p {
    text-align: center;
  }
  .studio .values .card img {
    right: -2rem;
    width: 12rem;
  }
  .studio .values .card:nth-child(1) img {
    left: -2rem;
  }
}

@media screen and (min-width: 992px) {
  .studio .studio-margin {
    font-size: 1.6rem;
    margin-left: 10.1rem;
    margin-right: 10.6rem;
  }
  .studio .expertise h2,
  .studio .values h2,
  .studio .trust h2,
  .studio .partners h2,
  .studio .services h2 {
    font-size: 5rem;
    padding-top: 4.5rem;
  }
  .studio .expertise h2 {
    padding-top: 3rem;
  }
  .studio .expertise h3,
  .studio .values h3,
  .studio .trust h3,
  .studio .partners h3,
  .studio .services h3 {
    font-size: 3rem;
  }
  .studio .expertise .card {
    background-color: transparent;
    margin-bottom: 8.1rem;
    max-width: 100%;
    min-height: 29.9rem;
    padding-bottom: 3.2rem;
    padding-top: 3.2rem;
  }
}
@media screen and (min-width: 992px) and (min-width: 1200px) {
  .studio .expertise .card {
    min-height: 27.5rem;
  }
}

@media screen and (min-width: 992px) {
  .studio .expertise .big-image {
    top: 4rem;
    z-index: -1;
  }
  .studio .expertise .icon {
    top: 28rem;
  }
}
@media screen and (min-width: 992px) and (min-width: 1061px) {
  .studio .expertise .icon {
    top: 25rem;
  }
}
@media screen and (min-width: 992px) and (min-width: 1164px) {
  .studio .expertise .icon {
    top: 23rem;
  }
}

@media screen and (min-width: 992px) {
  .studio .expertise .phone {
    top: 18rem;
  }
  .studio .expertise .hand {
    top: 14rem;
  }
  .studio .expertise .icon-eye {
    width: 12rem;
  }
  .studio .expertise .icon-mobile {
    width: 8rem;
  }
  .studio .expertise .icon-hand {
    width: 7rem;
  }
  .studio .expertise .card:nth-child(even) {
    width: 100%;
  }
  .studio .expertise .card:nth-child(even) .text {
    padding-left: 35rem;
  }
  .studio .expertise .card:nth-child(even) .big-image {
    float: left;
    left: -11rem;
    width: 45.5rem;
  }
  .studio .expertise .card:nth-child(odd) .big-image {
    float: right;
    right: -10.5rem;
    width: 45.5rem;
  }
  .studio .expertise .card:nth-child(odd) .icon {
    right: 40rem;
  }
  .studio .expertise .card:nth-child(odd) .text {
    padding-right: 35rem;
  }
  .studio .values {
    margin-top: 0;
  }
  .studio .values .card {
    border: solid 0.2rem #ffc200;
    margin-right: 1.2rem;
    min-width: 30rem;
    padding-top: 3.3rem;
    width: 34.8rem;
  }
  .studio .values .card p {
    margin-bottom: 12rem;
  }
  .studio .values .card:nth-child(1) img {
    right: 16rem;
  }
  .studio .values .card img {
    right: 6rem;
    width: 18rem;
  }
}

@media screen and (min-width: 1281px) {
  .studio .expertise {
    .card {
      width: 85%;
    }
    .card:nth-child(even) {
      margin-left: 0 !important;
    }
    .card:nth-child(odd) {
      margin-right: 0 !important;
    }
  }
}

.studio .banner {
  background: url('/assets/img/background/studio/background-header-studio.png');
  background: -webkit-image-set(
      url('/assets/img/background/studio/background-header-studio.png') 1x,
      url('/assets/img/background/studio/background-header-studio@2x.png') 2x,
      url('/assets/img/background/studio/background-header-studio@3x.png') 3x
    )
    no-repeat center;
  background-size: cover;
  height: 35rem;
}
.studio .banner .arrow {
  display: block;
  margin: 0.02rem auto;
}
.studio .banner .logo {
  height: 8.1rem;
  margin-top: 4.5rem;
}
.studio .banner h1 {
  font-size: 3rem;
  margin-bottom: 3rem;
  margin-top: 0;
}
.studio .banner .btn {
  font-size: 1.4rem;
  padding: 2.1rem;
  width: 20.8rem;
}
@media screen and (min-width: 992px) {
  .studio .banner {
    height: 60rem;
    margin-top: -1rem;
  }
  .studio .banner .arrow {
    height: 5.8rem;
  }
  .studio .banner .logo {
    height: 14.7rem;
    margin-bottom: 6.3rem;
    margin-top: 6rem;
  }
  .studio .banner h1 {
    font-size: 5rem;
    margin-bottom: 5.2rem;
  }
  .studio .banner .btn {
    font-size: 2rem;
    padding: 2.8rem;
    width: 24rem;
    height: 8rem;
  }
}

.studio .partners .genious {
  margin-bottom: 4rem;
  margin-top: 2.2rem;
  width: 15.2rem;
}

.studio .partners .blockquotes {
  background: url('/assets/img/background/studio/img-bg-challenges-mobile.png');
  background: -webkit-image-set(
    url('/assets/img/background/studio/img-bg-challenges-mobile.png') 1x,
    url('/assets/img/background/studio/img-bg-challenges-mobile@2x.png') 2x,
    url('/assets/img/background/studio/img-bg-challenges-mobile@3x.png') 3x
  );
  background-size: cover;
  height: 20rem;
}
.studio .partners .blockquotes img {
  width: 3rem;
}
.studio .partners .blockquotes .quote-left {
  margin-bottom: 1.3rem;
  margin-top: 2.4rem;
}
.studio .partners .blockquotes .quote-right {
  margin-bottom: 2rem;
  margin-top: 0.9rem;
}
.studio .partners .blockquotes p {
  color: #fff;
  font-family: 'Montserrat-Regular', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 1.8rem;
  line-height: normal;
  margin-left: 3rem;
  margin-right: 3rem;
}

@media screen and (min-width: 768px) {
  .studio .partners .genious {
    margin-bottom: 6rem;
    margin-top: 3rem;
  }
  .studio .partners .blockquotes {
    background: url('/assets/img/background/studio/img-bg-challenges-tablet.png')
      center;
    background: -webkit-image-set(
        url('/assets/img/background/studio/img-bg-challenges-tablet.png') 1x,
        url('/assets/img/background/studio/img-bg-challenges-tablet@2x.png') 2x,
        url('/assets/img/background/studio/img-bg-challenges-tablet@3x.png') 3x
      )
      center;
    background-size: cover;
    height: 30rem;
  }
  .studio .partners .blockquotes img {
    margin-bottom: 13rem;
    margin-top: 13.1rem;
    width: 5rem;
  }
  .studio .partners .blockquotes .quote-left {
    margin-left: 6.2rem;
  }
  .studio .partners .blockquotes .quote-right {
    margin-right: 6.2rem;
  }
  .studio .partners .blockquotes p {
    font-size: 3rem;
    margin-top: 9.6rem;
  }
}

@media screen and (min-width: 992px) {
  .studio .partners .genious {
    margin-bottom: 8.68rem;
    margin-top: 6.55rem;
    width: 24.3rem;
  }
  .studio .partners .blockquotes {
    background: url('/assets/img/background/studio/img-bg-challenges-desktop.png')
      center;
    background: -webkit-image-set(
        url('/assets/img/background/studio/img-bg-challenges-desktop.png') 1x,
        url('/assets/img/background/studio/img-bg-challenges-desktop@2x.png') 2x,
        url('/assets/img/background/studio/img-bg-challenges-desktop@3x.png') 3x
      )
      center;
    background-size: cover;
    height: 45rem;
    margin: auto;
    max-width: 192rem;
  }
  .studio .partners .blockquotes img {
    margin-bottom: 19.4rem;
    margin-top: 19.5rem;
    width: 7.9rem;
  }
  .studio .partners .blockquotes .quote-left {
    margin-left: 10.5rem;
  }
  .studio .partners .blockquotes .quote-right {
    margin-right: 10.5rem;
  }
  .studio .partners .blockquotes p {
    margin-top: 18.9rem;
  }
}

.studio .services .row {
  margin: auto;
}
@media screen and (max-width: 767px) {
  .studio .services .row {
    width: 32.5rem;
  }
  .studio .services .row div:first-child {
    margin-left: 0;
  }
}

.studio .services .footer {
  margin-top: 5.2rem;
}

.studio .services .left-part img {
  width: 32rem;
}

.studio .services .right-part {
  max-width: 32rem;
}

.studio .services .logo-block {
  padding: 0;
}

.studio .services .logo {
  background-color: #ffcb00;
  height: 10.43rem;
  margin: 0.4rem;
  margin-top: 0;
  max-height: 100%;
  max-width: 100%;
  text-align: center;
  text-transform: uppercase;
  width: 10.43rem;
}
.studio .services .logo p {
  color: #fff;
  font-size: 0.9rem;
  margin-bottom: 0;
  padding-bottom: 1.8rem;
  padding-top: 0.9rem;
}
.studio .services .logo img {
  width: 3.4rem;
}

@media screen and (min-width: 768px) {
  .studio .services .left-part img {
    width: 38.5rem;
  }
  .studio .services .right-part {
    max-width: 38.3rem;
  }
  .studio .services .logo {
    height: 12.45rem;
    width: 12.45rem;
    margin: 0.2rem;
    margin-top: 0;
    margin-bottom: 0.335rem;
  }
  .studio .services .logo img {
    width: 3.9rem;
  }
  .studio .services .logo p {
    font-size: 1.08rem;
    padding-bottom: 1.67rem;
    padding-top: 1.29rem;
  }
}

@media screen and (min-width: 992px) {
  .studio .services .left-part img {
    width: 44rem;
  }
  .studio .services .right-part {
    max-width: 54rem;
  }
  .studio .services .logo {
    height: 17.35rem;
    width: 17.4rem;
    margin-bottom: 0.45rem;
  }
  .studio .services .logo img {
    width: 4.8rem;
  }
  .studio .services .logo p {
    font-size: 1.8rem;
    padding-bottom: 2.8rem;
    padding-top: 3.3rem;
  }
}

@media screen and (min-width: 1200px) {
  .studio .services .left-part img {
    width: 53rem;
  }
  .studio .services .right-part {
    max-width: 65rem;
  }
  .studio .services .logo {
    height: 20.9rem;
    margin-bottom: 0.4rem;
    margin-right: 0.2rem;
    width: 21rem;
  }
  .studio .services .logo img {
    width: 5.1rem;
  }
  .studio .services .logo p {
    font-size: 2rem;
    padding-bottom: 2.8rem;
    padding-top: 3.3rem;
  }
}

.studio .trust .logo-block {
  padding: 0;
}

.studio .trust .logo-img {
  padding: 0;
  margin-bottom: 2rem;
  align-items: center;
  display: flex;
  height: 10rem;
  justify-content: center;

  &.active img,
  &:hover img {
    filter: grayscale(0%);
  }

  img {
    transition: all 0.3s ease;
    filter: grayscale(100%);
  }
}

.studio .trust .row {
  margin: 0;
}

.studio .trust .logo {
  margin-bottom: 2.7rem;
  margin-top: 2.7rem;
  max-height: 6.6rem;
}

@media screen and (min-width: 768px) {
  .studio .trust .row {
    padding-bottom: 5rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .studio .trust .logo {
    margin: 2.9rem 4rem;
    max-height: 7.4rem;
  }
}

@media screen and (min-width: 992px) {
  .studio .trust .row {
    padding-left: 7.9rem;
    padding-right: 7.9rem;
  }
  .studio .trust .logo {
    margin: 5.4rem 0;
    max-height: 9rem;
  }
  .studio .trust .logo-img {
    height: 15rem;
  }
}
